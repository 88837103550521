import React, { useRef, useState } from "react"
import Audio from "../components/Audio"
import Button from "../components/Button"
import Container from "../components/Container"
import Section from "../components/Section"
import Site from "../components/Site"
import Svg from "../components/Svg"
import { theMixes } from "../mixes-data"
import "../css/mixes.scss"

const MixesPage = () => {

  // Constructors and Lifecycle
  // --------------------------

  let theAudio                   = useRef(null)

  let [isPlaying, setPlaying]    = useState(false)
  let [showPlayer, setPlayer]    = useState(false)
  let [theMix, setMix]           = useState(0)
  let [theProgress, setProgress] = useState(0)
  let [theInfo, setInfo]         = useState(0)

  let theCurrentTime     = theAudio.current !== null ? theAudio.current.currentTime : 0
  let theCurrentDuration = theAudio.current !== null ? theAudio.current.duration    : 0

  let progressMin = Math.floor(theCurrentTime / 60)
  let progressSec = Math.floor(theCurrentTime - progressMin * 60)
  let durationMin = Math.floor(theCurrentDuration / 60)
  let durationSec = Math.floor(theCurrentDuration - durationMin * 60)


  // Helpers
  // -------

  const formatUnit = (int, unit = "m") => {
    if ( isNaN(int) ) {
      return "--"
    } else if ( int < 10 && unit === "s" ) {
      return `0${int}`
    } else {
      return `${int}`
    }
  }

  const cleanPosition = (abbr) => {
    let thePosition = null

    switch (abbr) {
      case "a" : thePosition = "Acoustic"; break
      case "b" : thePosition = "Bass";     break
      case "d" : thePosition = "Drums";    break
      case "e" : thePosition = "Electric"; break
      case "k" : thePosition = "Keys";     break
      default  :
        thePosition = "Auxiliary"
    }

    return thePosition
  }

  const getSeconds = (time) => {
    const timePieces       = time.split(":")
    const minutesAsSeconds = parseInt(timePieces.shift()) * 60
    const remainingSeconds = parseInt(timePieces.pop())
    const totalSeconds     = parseInt(minutesAsSeconds + remainingSeconds)

    return totalSeconds
  }

  const getCurrentTitle = () => { return `${theMixes[theMix].date}`      }
  const getArtist       = () => { return "Hillside"                      }
  const getAlbum        = () => { return "Mix Archive"                   }
  const audioPlay       = () => { theAudio.current.play()                }
  const audioPause      = () => { theAudio.current.pause()               }
  // const audioToggle     = () => { isPlaying ? audioPause() : audioPlay() }
  const audioLoad       = () => { theAudio.current.load()                }


  // Output
  // ------

  return (
    <Site
      title      = {showPlayer ? getCurrentTitle() : getAlbum()}
      className  = "hcc-mixes"
      image      = "/mixes/og.jpg"
      themeColor = "#eeeeee"
      data-datocms-noindex
    >
      <article>

        {/* Intro
        --- ----- */}

        <Section tag="header" className="hcc-mixes-intro">
          <Container size="lg">
            <h1>{getAlbum()}</h1>
          </Container>
        </Section>


        {/* Content
        --- ------- */}

        <Section className="hcc-mixes-content">
          <Container size="lg">
            <ol className="hcc-mixes-list">
              {theMixes.map((mixData, iMix) => {
                // if ( iMix > 9 ) {
                //   return false
                // }

                return (
                  <li
                    key       = {`mix-${mixData.slug}`}
                    className = {(theMix === iMix && showPlayer) ? "is-currently-playing" : null}
                  >
                    {/* eslint-disable-next-line */}
                    <header
                      role     = "button"
                      tabIndex = "-1"
                      onClick  = {e => {
                        if ( e.target.classList.contains("is-info-toggle") ) {
                          return
                        }
                        if ( theMix !== iMix ) {
                          setMix(iMix)
                          setProgress(0)
                          audioPause()
                          audioLoad()
                        }
                        if ( theMix === iMix ) {
                          setProgress(0)
                          theAudio.current.currentTime = 0
                        }
                        if ( ! isPlaying ) {
                          setPlaying(true)
                          setPlayer(true)
                        }
                        setInfo(iMix)
                        audioPlay()
                    }}
                  >
                      <h2>
                        <strong>{mixData.date}</strong>
                        <span>
                          {mixData.day !== null && <i>{mixData.day}</i>}
                          {mixData.events !== null && mixData.events.map(event => (
                            <React.Fragment key={`mix-event-${mixData.slug}-${event}`}>
                              <i>&nbsp;/&nbsp;</i>
                              <i>{event}</i>
                            </React.Fragment>
                          ))}
                        </span>
                      </h2>
                      <Button
                        label     = {theInfo === iMix ? <Svg type="close"/> : <Svg type="more"/>}
                        className = {theInfo === iMix ? "is-info-toggle has-no-outline is-active" : "is-info-toggle has-no-outline"}
                        onClick   = {() => {
                          if ( theInfo === iMix ) {
                            setInfo(false)
                          } else {
                            setInfo(iMix)
                          }
                        }}
                      />
                    </header>
                    <div className={theInfo === iMix ? "hcc-mix-info is-active" : "hcc-mix-info"}>
                      <div className="is-setlist">
                        <ol>
                          {mixData.setlist.map(songData => (
                            <li key={`setlist-${mixData.slug}-${songData.start}`}>
                              <Button
                                label     = {<><strong>{songData.start}</strong> &ndash; <span><i>&ldquo;{songData.title}&rdquo;</i> ({songData.singer})</span></>}
                                className = "is-text has-no-outline"
                                onClick   = {() => {
                                  if ( theMix !== iMix ) {
                                    setMix(iMix)
                                    audioPause()
                                    audioLoad()
                                  }

                                  setProgress(getSeconds(songData.start) / theCurrentDuration)
                                  theAudio.current.currentTime = getSeconds(songData.start)

                                  if ( ! isPlaying ) {
                                    setPlaying(true)
                                    setPlayer(true)
                                  }

                                  audioPlay()
                                }}
                              />
                            </li>
                          ))}
                        </ol>
                      </div>
                      <div className="is-band">
                        <ul>
                          {mixData.band.map(member => (
                            <li key={`member-${mixData.slug}-${member.player}`}>
                              <span><strong>{cleanPosition(member.position)}</strong></span> &ndash; <span>{member.player}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ol>
          </Container>
        </Section>
      </article>


      {/* Player
      --- ------ */}

      <div className={showPlayer ? "hcc-mixes-player is-active" : "hcc-mixes-player"}>
        <div className="is-controls">
          <Button
            label     = {isPlaying ? <Svg type="pause"/> : <Svg type="play"/>}
            className = "has-no-outline"
            onClick   = {() => {
              setPlaying( ! isPlaying )

              if ( ! isPlaying ) {
                audioPlay()
              } else {
                audioPause()
              }
            }}
          />
        </div>
        {/* eslint-disable-next-line */}
        <div
          className = "is-timeline"
          onClick   = {e => {
            const boundingRect   = e.target.getBoundingClientRect()
            const boundingStartX = boundingRect.x
            const boundingWidth  = boundingRect.width
            const pageX          = e.pageX
            const scale          = ((pageX - boundingStartX) / boundingWidth)

            setProgress(scale)

            setTimeout(() => {
              theAudio.current.currentTime = isNaN(theAudio.current.duration) ? 0 : theAudio.current.duration * scale
            }, 0)
          }}
        >
          <div className="is-info">
            <span className="is-line-1">{getCurrentTitle()}</span>
            <span className="is-line-2">{formatUnit(progressMin, "m")}:{formatUnit(progressSec, "s")} / {formatUnit(durationMin, "m")}:{formatUnit(durationSec, "s")}</span>
          </div>
          <div className="is-markers">
            {theMixes[theMix].setlist.map(songData => {
              if ( getSeconds(songData.start) !== 0 ) {
                return (
                  <i
                    key   = {`${theMixes[theMix].slug}-${songData.start}`}
                    style = {{left : `${(getSeconds(songData.start) / theCurrentDuration) * 100}%`}}
                  />
                )
              }
              return false
            })}
          </div>
          <i className="is-progress" style={{"transform" : `scaleX(${theProgress})`}}/>
        </div>
        <Audio
          ref                  = {theAudio}
          // src                  = {`https://hillside-worship.s3.amazonaws.com/archive/${theMixes[theMix].slug}.mp3`}
          src                  = {`https://hillside-worship.s3.us-west-2.amazonaws.com/archive/${theMixes[theMix].slug}.mp3`}
          title                = {getCurrentTitle()}
          preload              = "metadata"
          controls             = {false}
          mediaSessionMetaData = {{
            title   : getCurrentTitle(),
            artist  : getArtist(),
            album   : getAlbum(),
            artwork : [
              { src: "/mixes/artwork.jpg", sizes: "96x96",   type: "image/jpeg" },
              { src: "/mixes/artwork.jpg", sizes: "128x128", type: "image/jpeg" },
              { src: "/mixes/artwork.jpg", sizes: "192x192", type: "image/jpeg" },
              { src: "/mixes/artwork.jpg", sizes: "256x256", type: "image/jpeg" },
              { src: "/mixes/artwork.jpg", sizes: "384x384", type: "image/jpeg" },
              { src: "/mixes/artwork.jpg", sizes: "512x512", type: "image/jpeg" },
            ]
          }}
          onEnded = {() => {
            setPlayer(false)
            setPlaying(false)
          }}
          onTimeUpdate = {() => {
            setProgress(theAudio.current.currentTime / theAudio.current.duration) 
          }}
        />
      </div>

    </Site>
  )
}


// Export
// ------

export default MixesPage
