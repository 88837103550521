import React, { useRef } from "react"
import PropTypes from "prop-types"

const Audio = React.forwardRef(({
  src,
  autoplay,
  controls,
  crossorigin,
  loop,
  muted,
  preload,
  title,
  mediaSessionMetaData,
  onCanPlay,
  onCanPlayThrough,
  onChange,
  onEnded,
  onError,
  onLoadStart,
  onLoadedData,
  onLoadedMetadata,
  onPause,
  onPlay,
  onPlaying,
  onProgress,
  onSeeked,
  onSeeking,
  onTimeUpdate,
  onVolumeChange,
  ...props
}, ref) => {

  // Constructors
  // ------------

  const elAudio = useRef(null)


  // Sources
  // -------

  const sources = (src) => {
    let srcClean  = typeof src === 'string' ? [src] : src
    let srcOutput = []

    srcClean.map(el => {
      let ext = el.split('?').shift().split('.').pop()
      ext = ext === 'mp3' ? 'mpeg' : ext
      return srcOutput.push(<source key={el} src={el} type={`audio/${ext}`}/>)
    })

    return srcOutput
  }


  // Events
  // ------

  const onPlayLocal = () => {
    if ( onPlay ) {
      onPlay()
    }
    if (mediaSessionMetaData && "mediaSession" in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata(mediaSessionMetaData)
    }
  }


  // Output
  // ------

  return (
    <div className="hcc-audio" {...props}>
        {/* eslint-disable-next-line */}
        <audio
          autoPlay         = {autoplay}
          controls         = {controls}
          crossOrigin      = {crossorigin}
          loop             = {loop}
          muted            = {muted}
          preload          = {preload}
          title            = {title}
          onCanPlay        = {onCanPlay}
          onCanPlayThrough = {onCanPlayThrough}
          onChange         = {onChange}
          onEnded          = {onEnded}
          onError          = {onError}
          onLoadStart      = {onLoadStart}
          onLoadedData     = {onLoadedData}
          onLoadedMetadata = {onLoadedMetadata}
          onPause          = {onPause}
          onPlay           = {onPlayLocal}
          onPlaying        = {onPlaying}
          onProgress       = {onProgress}
          onSeeked         = {onSeeked}
          onSeeking        = {onSeeking}
          onTimeUpdate     = {onTimeUpdate}
          onVolumeChange   = {onVolumeChange}
          ref              = {ref || elAudio}
        >
          {sources(src)}
          {/* Sorry, but it appears your browser doesn't support embedded audio. To view this content, please try another browser. */}
        </audio>
    </div>
  )
})

Audio.defaultProps = {
  src                  : null,
  autoplay             : false,
  controls             : true,
  crossorigin          : "anonymous",
  loop                 : false,
  muted                : false,
  preload              : "none",
  title                : null,
  mediaSessionMetaData : null,
  onCanPlay            : null,
  onCanPlayThrough     : null,
  onChange             : null,
  onEnded              : null,
  onError              : null,
  onLoadStart          : null,
  onLoadedData         : null,
  onLoadedMetadata     : null,
  onPause              : null,
  onPlay               : null,
  onPlaying            : null,
  onProgress           : null,
  onSeeked             : null,
  onSeeking            : null,
  onTimeUpdate         : null,
  onVolumeChange       : null,
}

Audio.propTypes = {
  src                  : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  autoplay             : PropTypes.bool,
  controls             : PropTypes.bool,
  crossorigin          : PropTypes.string,
  loop                 : PropTypes.bool,
  muted                : PropTypes.bool,
  preload              : PropTypes.string,
  title                : PropTypes.string,
  mediaSessionMetaData : PropTypes.object,
  onCanPlay            : PropTypes.func,
  onCanPlayThrough     : PropTypes.func,
  onChange             : PropTypes.func,
  onEnded              : PropTypes.func,
  onError              : PropTypes.func,
  onLoadStart          : PropTypes.func,
  onLoadedData         : PropTypes.func,
  onLoadedMetadata     : PropTypes.func,
  onPause              : PropTypes.func,
  onPlay               : PropTypes.func,
  onPlaying            : PropTypes.func,
  onProgress           : PropTypes.func,
  onSeeked             : PropTypes.func,
  onSeeking            : PropTypes.func,
  onTimeUpdate         : PropTypes.func,
  onVolumeChange       : PropTypes.func,
}

export default Audio
